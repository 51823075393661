import React from 'react';
import './App.css';
import {BrowserRouter as Router} from 'react-router-dom';
import RootNav from './navigations/RootNav';
import HeaderBar from './components/HeaderBar/HeaderBar';
import TheFooter from './components/Footer/TheFooter';
import {Layout} from 'antd';
import './styles/styleGlobal.css';


const {Content} = Layout;
const {Footer} = Layout;


function App() {
  
  return (
    <Router basename="/">
      <Layout>
        <HeaderBar ></HeaderBar>
        <Content>
            <RootNav></RootNav>
        </Content>
        
      </Layout> 
    </Router>
  );
}

export default App;

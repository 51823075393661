import React, { useEffect, useState } from "react";
import "./LanguageDropdown.css";
import Flag from "react-flagkit";
import { useCookies } from "react-cookie";
import { log } from "console";

interface Language {
  code: string;
  name: string;
  id: number;
}

const languages: Language[] = [
  { code: "VN", name: "Vietnamese", id: 0 },
  { code: "GB", name: "English", id: 1 },
];

function LanguageDropdown() {
  const [cookies, setCookie] = useCookies(["Lang"]);

  const [selectedLanguage, setSelectedLanguage] = useState<Language>(
    languages[0]
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleDropdown = (): void => setIsOpen(!isOpen);

  const selectLanguage = (language: Language): void => {
    setSelectedLanguage(language);
    setIsOpen(false);
    setCookie("Lang", language.id);
  };

  useEffect(() => {
    if (cookies.Lang == null || cookies.Lang == undefined) {
      setCookie("Lang", 0);
      setSelectedLanguage(languages[0]);
    } else setSelectedLanguage(languages[cookies.Lang]);
  }, []);

  return (
    <div className="language-dropdown">
      <div className="selected-language" onClick={toggleDropdown}>
        <div className="flag-icon">
          <Flag country={selectedLanguage.code} size={40} />
        </div>
        <span>{selectedLanguage.name}</span>
      </div>
      {isOpen && (
        <div className="dropdown-list">
          {languages.map((language) => {
            return (
              <div
                key={language.code}
                className="dropdown-item"
                onClick={() => selectLanguage(language)}
              >
                <div className="flag-icon">
                  <Flag country={language.code} size={40} />
                </div>

                <span>{language.name}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default LanguageDropdown;

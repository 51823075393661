import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import "./style.css";
import { Helmet } from "react-helmet";
import { useCookies } from "react-cookie";
import { AccountApi } from "../../apis";
import { useHistory } from "react-router";

function RegisterScreen() {
  const [isLoginSubmit, setIsLoginSubmit] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const [textError, setTextError] = useState<any>("");
  const [textSuccess, setTextSuccess] = useState<string>("");
  const [cookies, setCookie] = useCookies(["Token"]);
  const history = useHistory();

  const onRegister = async (values: any) => {
    setIsLoginSubmit(true);
    setTextError("");
    setTextSuccess("");
    try {
      const res = await AccountApi.register({
        email: values.email,
        pirexId: values.pirexId,
        password: values.password,
      });
      onRegisterSuccess(res);
    } catch (error) {
      if (error !== undefined && error !== null) {
        setTextError(error);
      } else {
        setTextError("Server fail. Wait a few minutes and try again !");
      }
    }
    setIsLoginSubmit(false);
  };
  const onRegisterSuccess = async (res: any) => {
    form.resetFields();
    setTextSuccess("Register Sucess ! Back to Sign In");
    const timerBackLogin = setTimeout(() => {
      history.replace("/login");
    }, 3000);
  };
  const autoLogin = async () => {
    if (cookies.Token !== "null") history.replace("/");
  };
  useEffect(() => {
    forceUpdate({});
    autoLogin();
  }, []);
  return (
    <>
      <Helmet>
        <title>Sign Up | Pirex Games</title>
      </Helmet>
      <div className="back-container"></div>
      <div className="login-container">
        <Form form={form} onFinish={onRegister} layout="vertical">
          <div className="form-login">
            <div className="title-login">SIGN UP</div>
            <div className="input-auth-container">
              {textError !== "" ? (
                <div className="text-error-login">
                  {textError.toUpperCase()}
                </div>
              ) : null}
              {textSuccess !== "" ? (
                <div className="text-notification-login">
                  {textSuccess.toUpperCase()}
                </div>
              ) : null}

              <Form.Item
                name="email"
                rules={[{ required: true, message: "Please enter Email!" }]}
              >
                <Input
                  className="input-auth"
                  placeholder="Email"
                  type="text"
                  prefix={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 0 24 24"
                      width="24px"
                      fill="#828282"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
                    </svg>
                  }
                />
              </Form.Item>
              <Form.Item
                name="pirexId"
                rules={[
                  { required: true, message: "Please enter PirexID!" },
                  { max: 16, message: "Password must be max 20 characters." },
                ]}
              >
                <Input
                  className="input-auth"
                  placeholder="Pirex ID"
                  type="text"
                  prefix={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 0 24 24"
                      width="24px"
                      fill="#828282"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
                    </svg>
                  }
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please enter Password!" },
                  { min: 8, message: "Password must be minimum 8 characters." },
                  { max: 20, message: "Password must be max 20 characters." },
                ]}
              >
                <Input
                  className="input-auth"
                  placeholder="Password"
                  type="password"
                  prefix={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 0 24 24"
                      width="24px"
                      fill="#828282"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" />
                    </svg>
                  }
                />
              </Form.Item>
              <Form.Item
                name="confirm"
                rules={[
                  { required: true, message: "Please confirm Password!" },
                  { max: 20, message: "Password must be max 20 characters." },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input
                  className="input-auth"
                  placeholder="Confirm Password"
                  type="password"
                  prefix={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 0 24 24"
                      width="24px"
                      fill="#828282"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" />
                    </svg>
                  }
                />
              </Form.Item>
              <Form.Item shouldUpdate>
                {() => (
                  <div className="button-login-container">
                    <Button
                      loading={isLoginSubmit}
                      type="primary"
                      htmlType="submit"
                      className="button-login"
                      disabled={
                        !form.isFieldsTouched(true) ||
                        !!form
                          .getFieldsError()
                          .filter(({ errors }) => errors.length).length
                      }
                    >
                      Sign Up
                    </Button>
                  </div>
                )}
              </Form.Item>
            </div>
            <div className="line">
              <div className="or-text-container">OR</div>
            </div>
            <div
              className="button-register"
              onClick={() => {
                history.push("/login");
              }}
            >
              Already Have An Account ? Sign In Now
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}

export default RegisterScreen;

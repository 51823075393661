import React, { useRef } from "react";
import { GameDetailType, GameType } from "../../types/index";
import "./style.css";
import { CarouselRef } from "antd/lib/carousel";
import { useMediaQuery } from "react-responsive";
import pirexLogo from "../../assets/img/pirexgames-logo.png";

type Props = {
  game: GameDetailType | undefined;
  gameData: GameType | undefined;
  urlTrailer: string;
};
const NumFormat = (num: any) => {
  return Intl.NumberFormat("en-US", { maximumSignificantDigits: 10 }).format(
    num
  );
};
function DetailGame({ game, urlTrailer, gameData }: Props) {
  const carouselRef = useRef<CarouselRef>(null);
  const isResMb = useMediaQuery({ query: "(min-width: 512px)" });
  return (
    <div className="modal-content">
      <div className="game-icon">
        <img src={gameData?.urlIcon} />
      </div>
      <div className="title-modal">{gameData?.gameName}</div>
      <div className="genres-game-modal">{gameData?.genre}</div>
      <div className="game-reviews-modal">
        <div className="rate-point-modal">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#000000"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
          </svg>
          {game?.scoreText == null || undefined ? "NaN" : game?.scoreText}
        </div>
        <div className="rate-point-modal">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            enable-background="new 0 0 24 24"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#000000"
          >
            <g>
              <path d="M0,0h24v24H0V0z" fill="none" />
            </g>
            <g>
              <path d="M20,2H4C2.9,2,2,2.9,2,4v18l4-4h14c1.1,0,2-0.9,2-2V4C22,2.9,21.1,2,20,2z M13.57,11.57L12,15l-1.57-3.43L7,10l3.43-1.57 L12,5l1.57,3.43L17,10L13.57,11.57z" />
            </g>
          </svg>
          {NumFormat(game?.ratings)}
        </div>
        <div className="rate-point-modal">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#000000"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" />
          </svg>
          {NumFormat(game?.maxInstalls)}
        </div>
      </div>
      <div className="game-item-summary">{gameData?.summary}</div>
      <div
        className="button-see-now"
        onClick={() => {
          window.open(urlTrailer);
        }}
      >
        <div className="text-see-now">TRAILER</div>
        <div className="icon-see-now d-flex j-center alg-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            enable-background="new 0 0 20 20"
            height="32px"
            viewBox="0 0 20 20"
            width="32px"
            fill="#000000"
          >
            <g>
              <rect fill="none" height="20" width="20" />
            </g>
            <g>
              <path d="M10,2c-4.42,0-8,3.58-8,8s3.58,8,8,8s8-3.58,8-8S14.42,2,10,2z M8,13.5v-7l5.5,3.5L8,13.5z" />
            </g>
          </svg>
        </div>
      </div>
      {/* <div className="game-item-logo-publisher">
        <img src={pirexLogo} />
      </div> */}
    </div>
  );
}
export default DetailGame;

import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import "./style.css";
import { Helmet } from "react-helmet";
import { useCookies } from "react-cookie";
import { AccountApi } from "../../apis";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { login as loginAction } from '../../redux/actions/accountActions';
import { RootState } from "../../redux/reducers";

function ResetPasswordScreen() {
    const { token } = useParams<{token:string}>();
    const [isLoginSubmit, setIsLoginSubmit] = useState<boolean>(false);
    const [isVerifying, setIsVerifying] = useState<boolean>(true);
    const [textVerifying, setTextVerifying] = useState<string>("Verifying...");
    const [form] = Form.useForm();
    const [, forceUpdate] = useState({});
    const [textError, setTextError] = useState<any>("");
    const [textSuccess, setTextSuccess] = useState<string>("");
    const [cookies, setCookie] = useCookies(['Token']);
    const history = useHistory();
    const dispatch = useDispatch();
    const account = useSelector((state:RootState) => state.account)
    const { accountId = '' } = account || {};

    const onCreateNewPass = async (values: any) => {
        setIsLoginSubmit(true);
        setTextError("")
        setTextSuccess("")
        try {
            const res = await AccountApi.newPassword({
                accountId: accountId,
                newPassword: values.password,
                accessToken: cookies.Token
            })
            onCreateNewPassSuccess(res);
        }
        catch (error) {
            if (error !== undefined && error !== null) {
                setTextError(error)
            } else {
                setTextError("Server fail. Wait a few minutes and try again !");
            }
        }
        setIsLoginSubmit(false);
    }
    const onCreateNewPassSuccess = async (res: any) => {
        setTextSuccess("Change Password Sucess ! We will back to Home")
        setIsVerifying(true);
        setTextVerifying("Directing...");
        const timerBackLogin = setTimeout(() => {
            history.replace('/');
        }, 3000);
    }
    const autoLogin = async () => {
        if (cookies.Token !== 'null') {
            history.replace('/');
        } else {
            verifyToken();
        }
    }
    const verifyToken = async () => {
        try {
            const res = await AccountApi.verifyResetPwd(token);
            setIsVerifying(false);
            setTextError("");
            setTextSuccess("Verify Email Successful! You can create new password now.")
            setCookie('Token',res.token);
            dispatch(loginAction(res.account))
        } catch (error){
            setTextVerifying("Fail to verify");
            setTextError(error)
        }
    }
    useEffect(() => {
        forceUpdate({});
        autoLogin();
    }, []);
    return (
        <>
            <Helmet><title>Reset Password | Pirex Games</title></Helmet>
            <div className="back-container"></div>
            <div className="login-container">
                <Form form={form} onFinish={onCreateNewPass} layout="vertical">
                    <div className="form-login" style={{ marginTop: "200px" }}>
                        <div className="title-login">Reset Password</div>
                        <div className="input-auth-container">
                            {textError !== ''
                                ? <div className="text-error-login">
                                    {textError.toUpperCase()}
                                </div> : null
                            }
                            {
                                textSuccess !== '' ?
                                    <div className="text-notification-login">
                                        {textSuccess.toUpperCase()}
                                    </div> : null
                            }
                            {
                                isVerifying ?
                                    <div>
                                        <Form.Item
                                            name="loadingverify"
                                        >
                                            <div className="button-login-container">
                                                    <Button
                                                        loading={isVerifying}
                                                        type="primary"
                                                        className="button-login"
                                                        disabled={
                                                            !form.isFieldsTouched(true) ||
                                                            !!form
                                                                .getFieldsError()
                                                                .filter(({ errors }) => errors.length).length
                                                        }
                                                    >
                                                        {textVerifying}
                                                    </Button>
                                                </div>
                                        </Form.Item>
                                    </div> :
                                    <div>
                                        <Form.Item
                                            name="password"
                                            rules={[
                                                { required: true, message: 'Please enter Password!' },
                                                { min: 8, message: 'Password must be minimum 8 characters.' },
                                                { max: 20, message: 'Password must be max 20 characters.' },
                                            ]}
                                        >
                                            <Input
                                                className="input-auth"
                                                placeholder="New Password"
                                                type="password"
                                                prefix={<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#828282"><path d="M0 0h24v24H0z" fill="none" /><path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" /></svg>}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="confirm"
                                            rules={[
                                                { required: true, message: 'Please confirm Password!' },
                                                { max: 20, message: 'Password must be max 20 characters.' },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue('password') === value) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input
                                                className="input-auth"
                                                placeholder="Confirm New Password"
                                                type="password"
                                                prefix={<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#828282"><path d="M0 0h24v24H0z" fill="none" /><path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" /></svg>}
                                            />
                                        </Form.Item>
                                        <Form.Item shouldUpdate>
                                            {() => (
                                                <div className="button-login-container">
                                                    <Button
                                                        loading={isLoginSubmit}
                                                        type="primary"
                                                        htmlType="submit"
                                                        className="button-login"
                                                        disabled={
                                                            !form.isFieldsTouched(true) ||
                                                            !!form
                                                                .getFieldsError()
                                                                .filter(({ errors }) => errors.length).length
                                                        }
                                                    >
                                                        Create New Password
                                                    </Button>
                                                </div>
                                            )}
                                        </Form.Item>
                                    </div>
                            }

                        </div>
                    </div>
                </Form>
            </div>
        </>
    );
}

export default ResetPasswordScreen;

import {GameDetailType} from '../../types/index'

export type Games = {
   gameData: GameDetailType[] | null;
}

const initialState = {
    gameData: null,
}
export type GamesType = {
    gameData: GameDetailType[];
    type: string;
}
const ourGames = (state: Games = initialState, action: GamesType) => {
    switch (action.type) {
        case "set":
            return action;
        default:
            return state;
    }
}

export default ourGames;
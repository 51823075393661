import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import "./styleRes.css";
import { Layout, Button, Menu, Dropdown, Drawer } from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  AndroidFilled,
  CaretDownOutlined,
  CrownFilled,
  MenuOutlined,
  SafetyCertificateFilled,
} from "@ant-design/icons";
import idLinkto from "../../assets/data/IdLinkTo.json";
import MediaQuery from "react-responsive";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import {
  logout,
  login as loginAction,
} from "../../redux/actions/accountActions";
import { AccountApi } from "../../apis";
import LanguageDropdown from "../Language/LanguageDropdown";

const { Header } = Layout;

const tabs = [
  {
    title: "GAMES",
    linkTo: "/",
    titleHelmet: "Pirex Games",
    icon: '<svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 24 24" width="30px" fill="#FFFFFF"><path d="M0 0h24v24H0z" fill="none"/><path d="M15 7.5V2H9v5.5l3 3 3-3zM7.5 9H2v6h5.5l3-3-3-3zM9 16.5V22h6v-5.5l-3-3-3 3zM16.5 9l-3 3 3 3H22V9h-5.5z"/></svg>',
    color: "#be261c",
  },
];

type TabProps = {
  title: string;
  active: boolean;
};

function TabBtn({ title, active }: TabProps) {
  return (
    <div className={active ? "tab-btn-active" : "tab-btn-inactive"}>
      <div
        className={
          active ? "title-container-tab" : "title-container-tab-inactive"
        }
      >
        <span>{title}</span>
      </div>
      <div className={active ? "bottom-tab-active" : "bottom-tab-inactive"} />
    </div>
  );
}

const menuLogo = (
  <Menu className="logo-dropdown">
    <Menu.Item
      className="platform-dropdown-item"
      icon={<SafetyCertificateFilled style={{ color: "#35d467" }} />}
    >
      <Link to="/privacy-policy" onClick={() => window.scrollTo(0, 0)}>
        PRIVACY POLICY
      </Link>
    </Menu.Item>
    <Menu.Item
      className="platform-dropdown-item"
      icon={<CrownFilled style={{ color: "#f5a742" }} />}
    >
      <Link to="/who-we-are" onClick={() => window.scrollTo(0, 0)}>
        WHO WE ARE
      </Link>
    </Menu.Item>
  </Menu>
);

function HeaderBar() {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [cookies, setCookie] = useCookies(["Token"]);
  const location = useLocation();
  const account = useSelector((state: RootState) => state.account);
  const { pirexID = "", accountId = "", avatar = "" } = account || {};
  const dispatch = useDispatch();
  const history = useHistory();

  const onLoginSuccess = async (res: any) => {
    setCookie("Token", res.token);
    dispatch(loginAction(res.account));
  };
  const showDrawer = () => {
    setVisibleDrawer(true);
  };
  const onCloseDrawer = () => {
    setVisibleDrawer(false);
  };
  const onLogout = () => {
    setCookie("Token", null);
    dispatch(logout());
  };
  const autoLogin = async () => {
    if (cookies.Token !== "null") {
      try {
        const res = await AccountApi.login({
          loginID: "",
          password: "",
          accessToken: cookies.Token,
        });
        onLoginSuccess(res);
      } catch (error) {
        setCookie("Token", null);
      }
    }
  };
  useEffect(() => {
    let isHaveLinkTab = false;
    idLinkto.forEach((item, index) => {
      if (location.pathname === item.linkTo) {
        setActiveTabIndex(index);
        isHaveLinkTab = true;
      }
    });
    if (!isHaveLinkTab) {
      setActiveTabIndex(-1);
    }
  }, [activeTabIndex, location]);

  useEffect(() => {
    autoLogin();
  }, []);

  return (
    <div>
      <Header className="header">
        <MediaQuery minWidth={769}>
          <div className="header-container">
            <div className="d-flex">
              <div className="logo-header-container">
                <span className={"logo-header"}></span>
              </div>
              <div className="divide-container">
                <div className="divide-bar-logo-header"></div>
              </div>
              <div className="tabs-left">
                {tabs.map((item, index) => (
                  <Link
                    to={item.linkTo}
                    key={`TABHEADER_${index}`}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    <TabBtn
                      title={item.title}
                      active={activeTabIndex === index}
                    />
                  </Link>
                ))}
              </div>
            </div>

            <div className="tabs-right">
              <LanguageDropdown></LanguageDropdown>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={769}>
          <div className="header-maxwidth-769">
            <div className="logo-header-container">
              <span
                className="logo-header"
                onClick={() => {
                  history.replace("/");
                }}
              ></span>
            </div>
            <div className="tabs-right">
              <LanguageDropdown></LanguageDropdown>
            </div>
          </div>
        </MediaQuery>
        {/* <Drawer
          className="drawer"
          title={<div className="logo-container-res"></div>}
          placement="right"
          onClose={onCloseDrawer}
          visible={visibleDrawer}
          width="300px"
          closeIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="34px"
              viewBox="0 0 24 24"
              width="34px"
              fill="#000000"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
            </svg>
          }
          closable={true}
          mask={true}
          bodyStyle={{
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 0,
            paddingTop: 0,
            display: "flex",
            flexDirection: "column",
          }}
          headerStyle={{
            border: 0,
            color: "white",
          }}
        >
          <div className="drawer-body-container">
            <div className="drawer-tab-container">
              {tabs.map((item, index) => (
                <Link
                  key={`TABDRAWER_${index}`}
                  to={item.linkTo}
                  onClick={onCloseDrawer}
                >
                  <div className="btn-in-drawer">{item.title}</div>
                </Link>
              ))}
            </div>
            <div className="drawer-account">
              {pirexID === "" ? (
                <Link to={"/login"} onClick={onCloseDrawer}>
                  <div className="button-login button-login-drawer">
                    SIGN IN
                  </div>
                </Link>
              ) : (
                <div className="profile-container">
                  <div className="account-info">
                    <img src={avatar} />
                  </div>
                  <div className="pirex-id">
                    {pirexID === null ? "" : pirexID}
                  </div>
                  <div onClick={onLogout} className="button-logout">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="22px"
                      viewBox="0 0 24 24"
                      width="30px"
                      fill="#FFFFFF"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z" />
                    </svg>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Drawer> */}
      </Header>
    </div>
  );
}

export default HeaderBar;

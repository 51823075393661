
import { useCookies } from 'react-cookie';
import {AccountType} from '../../types';
import {ACCOUNT_TYPE} from '../types';

const {LOGIN, LOGOUT} = ACCOUNT_TYPE;


export const login = (user: AccountType) => {
  return {
    type: LOGIN,
    payload: user,
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};

import React from "react";
import "./Smoke.css";
import smoke from "./smoke.png";

function Smoke() {
  return (
    <div>
      <div className="smoke-wrap">
        <img className="smoke" src={smoke} alt="smoke" />
      </div>
      <div className="smoke-wrap">
        <img className="smoke2" src={smoke} alt="smoke" />
      </div>
      <div className="smoke-wrap">
        <img className="smoke3" src={smoke} alt="smoke" />
      </div>
    </div>
  );
}
export default Smoke;

import React, { useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import PrivacyPolicy from "../screens/PrivacyPolicy/PrivacyPolicyScreen";
import GamesScreen from "../screens/Games/GamesScreen";
import AboutUs from "../screens/AboutUs/AboutUs";
import PrexCode from "../screens/PrexCode/PrexCode";

import "./style.css";
import "../styles/styleGlobal.css";
import LoginScreen from "../screens/Login/LoginScreen";
import Register from "../screens/Register/RegisterScreen";
import games from "../assets/data/Games.json";
import axios from "axios";
import { GameDetailType } from "../types";
import ForgotPassword from "../screens/ForgotPassword/ForgotPassword";
import ResetPasswordScreen from "../screens/ResetPassword/ResetPasswordScreen";

function RootNav() {
  let location = useLocation();
  const [listGameDetail, setListGameDetail] = useState<GameDetailType[]>([]);
  const getListDetail = async () => {
    const listRequest = await games.map((game, index) => {
      return axios.get(game.urlReqDetail);
    });
    Promise.all(listRequest).then((values) => {
      const result = values.map((value) => value.data);
      setListGameDetail(result);
    });
  };
  useEffect(() => {
    getListDetail();
  }, []);
  return (
    <div className="content" style={{ background: "var(--backDark-color)" }}>
      <Switch location={location}>
        <Route path="/login" children={<LoginScreen />} />
        <Route path="/register" children={<Register />} />
        <Route path="/forgot-password" children={<ForgotPassword />} />
        <Route
          path="/reset-password/:token"
          children={<ResetPasswordScreen />}
        />
        <Route exact path="/privacy-policy" children={<PrivacyPolicy />} />
        <Route exact path="/pirex-code" children={<PrexCode />} />
        <Route exact path="/who-we-are">
          <AboutUs></AboutUs>
        </Route>
        <Route exact path="/">
          <GamesScreen listGames={listGameDetail} />
        </Route>
        <Route path="*" children={<GamesScreen listGames={listGameDetail} />} />
      </Switch>
    </div>
  );
}

export default RootNav;

import {ACCOUNT_TYPE} from '../types';
import {AccountType, ActionType} from './../../types/index';

const {LOGIN, LOGOUT} = ACCOUNT_TYPE;

const initState: AccountType | Object = {};

const accountReducer = (state = initState, action: ActionType) => {
  switch (action.type) {
    case LOGIN:
      return action.payload;
    case LOGOUT:
      return {};
    default:
      return state;
  }
};

export default accountReducer;

import React from "react";
import "./style.css";
import { GameDetailType, GameType } from "../../types/index";
import GameItem from "../GameItem/GameItem";
import GameItemLoading from "../GameItem/GameItemLoading";

type Props = {
  games: GameType[];
  listGameDetail: GameDetailType[];
};

function OurGames({ games, listGameDetail }: Props) {
  // console.log(listGameDetail);
  return (
    <div className="ourgames-container">
      <div className="list-game-carousel-container">
        {games.map((game, index) => (
          <GameItem
            key={`GAMEITEM_${index}`}
            gameDetailInfo={listGameDetail[index]}
            game={game}
            url={game.urlTrailer}
          ></GameItem>
        ))}
      </div>
    </div>
  );
}
export default OurGames;

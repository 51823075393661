import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import "./style.css";
import { LoginType } from "../../types";
import { Helmet } from "react-helmet";
import { login as loginAction } from '../../redux/actions/accountActions';
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { AccountApi } from "../../apis";
import { useHistory } from "react-router";

function ForgotPassword() {
    const [isLoginSubmit, setIsLoginSubmit] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [, forceUpdate] = useState({});
    const [textError, setTextError] = useState<any>("");
    const [textSuccess, setTextSuccess] = useState<string>("");
    const [cookies, setCookie] = useCookies(['Token']);
    const history = useHistory();

    const onSendMail = async (values: any) => {
        setIsLoginSubmit(true);
        setTextError("")
        setTextSuccess("")
        try {
            const res = await AccountApi.sendMailResetPwd({
                email: values.email,
                urlCallBack: window.location.origin + "/reset-password/"
            })
            onSendMailSuccess(res);
        } catch (error) {
            if (error !== undefined && error !== null) {
                setTextError(error)
            } else {
                setTextError("Server fail. Wait a few minutes and try again !");
            }
        }
        setIsLoginSubmit(false);
    };

    const onSendMailSuccess = async (res: any) => {
        setTextSuccess(res);
        form.resetFields();
    }
    const autoLogin = async () => {
        if (cookies.Token !== 'null') history.replace('/');
    }
    useEffect(() => {
        forceUpdate({});
        autoLogin();
    }, []);
    return (
        <>
            <Helmet><title>Forgot Password | Pirex Games</title></Helmet>
            <div className="back-container"></div>
            <div className="login-container">
                <Form form={form} onFinish={onSendMail} layout="vertical">
                    <div className="form-login" style={{ marginTop: "200px" }}>
                        <div className="title-login">FORGOT PASSWORD</div>
                        <div className="input-auth-container">
                            {textError !== ''
                                ? <div className="text-error-login">
                                    {textError.toUpperCase()}
                                </div> : null
                            }
                            {
                                textSuccess !== '' ?
                                    <div className="text-notification-login">
                                        {textSuccess.toUpperCase()}
                                    </div> : null
                            }

                            <Form.Item
                                name="email"
                                rules={[{ required: true, message: "Please enter PirexID or Email!" }]}
                            >
                                <Input
                                    className="input-auth"
                                    placeholder="Your Email"
                                    type="text"
                                    prefix={<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#828282"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" /></svg>}
                                />
                            </Form.Item>
                            <Form.Item shouldUpdate>
                                {() => (
                                    <div className="button-login-container">
                                        <Button
                                            loading={isLoginSubmit}
                                            type="primary"
                                            htmlType="submit"
                                            className="button-login"
                                            disabled={
                                                !form.isFieldsTouched(true) ||
                                                !!form
                                                    .getFieldsError()
                                                    .filter(({ errors }) => errors.length).length
                                            }
                                        >
                                            Send Confirm To Email
                                        </Button>
                                    </div>
                                )}
                            </Form.Item>
                        </div>
                        <div className="line">
                            <div className="or-text-container">OR</div>
                        </div>
                        <div className="button-register" onClick={() => { history.push('/login') }}>
                            Back to Login
                        </div>
                    </div>
                </Form>
            </div>
        </>
    );
}

export default ForgotPassword;

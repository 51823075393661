import axios from "axios";
import { LoginType } from "../types";
import { Endpoint } from "./Endpoint";
import requestApi from "./requestApi";

export default class AccountApi{
    
    static login({loginID,password,accessToken} : {loginID: string, password: string, accessToken:any}){
        return axios.post(Endpoint.pirexservices + "Account/login",{
            loginId: loginID,
            password: password,
            
        },{
            headers: {
                token: accessToken, 
            }
        })
        .then(res => {
            return res.data;
        })
        .catch(err => {
            console.clear();
            if (err.response.status !== 400) throw "The server has a problem";
            throw err.response.data;
        });
    } 
    static loginWithGoogle({email,avatar} : {email: string, avatar: string}){
        return axios.post(Endpoint.pirexservices + "Account/login-with-google",{
            email: email,
            avatar: avatar,
            
        })
        .then(res => {
            return res.data;
        })
        .catch(err => {
            console.clear();
            if (err.response.status !== 400) throw "The server has a problem";
            throw err.response.data;
        });
    } 
    static loginWithFaceBook({email,avatar} : {email: string, avatar: string}){
        return axios.post(Endpoint.pirexservices + "Account/login-with-facebook",{
            email: email,
            avatar: avatar,
            
        })
        .then(res => {
            return res.data;
        })
        .catch(err => {
            console.clear();
            if (err.response.status !== 400) throw "The server has a problem";
            throw err.response.data;
        });
    } 
    static register({email, pirexId, password}: {email: string, pirexId: string, password: string}) {
        return axios.post(Endpoint.pirexservices + "Account/register",{
            email: email,
            pirexId: pirexId,
            password: password
        })
        .then(res => {
            return res.data;
        })
        .catch(err => {
            console.clear();
            if (err.response.status !== 400) throw "The server has a problem";
            throw err.response.data;
        })
    }
    static sendMailResetPwd({email,urlCallBack}:{email:string,urlCallBack:string}){
        return axios.post(Endpoint.pirexservices + "Account/send-email-reset-password",{
            email: email,
            urlCallBack: urlCallBack,
        })
        .then(res => {
            return res.data;
        })
        .catch(err => {
            console.clear();
            if (err.response.status !== 400) throw "The server has a problem";
            throw err.response.data;
        })
    }
    static verifyResetPwd(token:string){
        return axios.post(Endpoint.pirexservices + "Account/verify-email-password-reset",{
            token:token
        })
        .then (res => {
            return res.data;
        })
        .catch (err => {
            console.clear();
            if (err.response.status !== 400) throw "The server has a problem";
            throw err.response.data;
        })
    }
    static newPassword({accountId,newPassword,accessToken}:{accountId:string; newPassword:string; accessToken:string}){
        return axios.post(Endpoint.pirexservices + "Account/new-password",{
            accountId: accountId,
            newPassword: newPassword
        },{
            headers: {
                Authorization: 'Bearer ' + accessToken,
            }
        })
        .then (res => {
            return res.data;
        })
        .catch (err => {
            console.clear();
            if (err.response.status === 401) throw "Access denied!";
            if (err.response.status !== 400) throw "The server has a problem";
            throw err.response.data;
        })
    }
}
import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import "./style.css";
import { LoginType } from "../../types";
import { Helmet } from "react-helmet";
import { login as loginAction } from '../../redux/actions/accountActions';
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import {AccountApi} from "../../apis";
import { useHistory } from "react-router";

function LoginScreen() {
  const [isLoginSubmit, setIsLoginSubmit] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const [textError, setTextError] = useState<any>("");
  const [textSuccess, setTextSuccess] = useState<string>("");
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(['Token']);
  const history = useHistory();

  const onLogin = async (values: LoginType) => {
    setIsLoginSubmit(true);
    setTextError("")
    setTextSuccess("")
    try {
      const res = await AccountApi.login({
        loginID:values.loginID,
        password: values.password,
        accessToken: "",
      })
      onLoginSuccess(res);
    } catch (error){
      if (error !== undefined && error !== null) {
        setTextError(error)
      } else {
        setTextError("Server fail. Wait a few minutes and try again !");
      }
    }
    setIsLoginSubmit(false);
  };

  const onLoginSuccess = async (res: any) => {
    setTextSuccess("sign in success, wait a seconds !")
    setCookie('Token',res.token);
    dispatch(loginAction(res.account))
    history.replace("/");
  }
  const responseGoogle = async (response: any) => {
    setIsLoginSubmit(true);
    setTextError("")
    setTextSuccess("")
    let data = response.profileObj;
    try {
      const res = await AccountApi.loginWithGoogle({
        email: data.email,
        avatar: data.imageUrl
      })
      onLoginSuccess(res);
    } catch (error){
      if (error !== undefined && error !== null) {
        setTextError(error)
      } else {
          setTextError("Server fail. Wait a few minutes and try again !");
      }
    }
    setIsLoginSubmit(false);
  };
  const responseFacebook = async (response: any) => {
    let data = response;
    setIsLoginSubmit(true);
    setTextError("")
    setTextSuccess("")
    try {
      const res = await AccountApi.loginWithFaceBook({
        email: data.userID,
        avatar: data.picture.data.url
      })
      onLoginSuccess(res);
    } catch (error){
      if (error !== undefined && error !== null) {
        setTextError(error)
      } else {
        setTextError("Server fail. Wait a few minutes and try again !");
      }
    }
    setIsLoginSubmit(false);
  };
  const autoLogin = async () => {
    if (cookies.Token !== 'null') history.replace('/');
  }
  useEffect(() => {
    forceUpdate({});
    autoLogin();
  }, []);
  return (
    <>
      <Helmet><title>Sign In | Pirex Games</title></Helmet>
      <div className="back-container"></div>
      <div className="login-container">
        <Form form={form} onFinish={onLogin} layout="vertical">
          <div className="form-login">
            <div className="title-login">SIGN IN</div>
            <div className="input-auth-container">
              {textError !== ''
                ?<div className="text-error-login">
                {textError.toUpperCase()}
                </div> : null
              }
              {
                textSuccess !== '' ?
                <div className="text-notification-login">
                {textSuccess.toUpperCase()}
                </div> : null
              }
              
              <Form.Item
                name="loginID"
                rules={[{ required: true, message: "Please enter PirexID or Email!" }]}
              >
                <Input
                  className="input-auth"
                  placeholder="Pirex ID or Email"
                  type="text"
                  prefix={<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#828282"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/></svg>}
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: 'Please enter Password!' },
                  { min: 8, message: 'Password must be minimum 8 characters.' },
                  { max: 20, message: 'Password must be max 20 characters.' },
                ]}
              >
                <Input.Password 
                  className="input-auth"
                  placeholder="Password"
                  prefix={<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#828282"><path d="M0 0h24v24H0z" fill="none"/><path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"/></svg>}

                />
              </Form.Item>
              <div className="forgot-pwd-container">
                <div className="button-forgot-pwd" onClick={()=>{history.push('/forgot-password')}}>Forgot Password</div>
              </div>
              <Form.Item shouldUpdate>
                {() => (
                  <div className="button-login-container">
                    <Button
                      loading={isLoginSubmit}
                      type="primary"
                      htmlType="submit"
                      className="button-login"
                      disabled={
                        !form.isFieldsTouched(true) ||
                        !!form
                          .getFieldsError()
                          .filter(({ errors }) => errors.length).length
                      }
                    >
                      Sign In
                    </Button>
                  </div>
                )}
              </Form.Item>
            </div>
            <div className="line">
              <div className="or-text-container">OR</div>
            </div>
            <div className="login-third-party">
              <GoogleLogin
                disabled={isLoginSubmit}
                className="btn-login-with-google"
                clientId="893952299635-ofdmhdcabvvepsmt92bipe01ch5mfp2t.apps.googleusercontent.com"
                onSuccess={responseGoogle}
                buttonText=""
                cookiePolicy={"single_host_origin"}
                icon={false}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                  width="144px"
                  height="144px"
                >
                  <path
                    fill="#FFC107"
                    d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                  />
                  <path
                    fill="#FF3D00"
                    d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                  />
                  <path
                    fill="#4CAF50"
                    d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                  />
                  <path
                    fill="#1976D2"
                    d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                  />
                </svg>
              </GoogleLogin>
              <FacebookLogin
                isDisabled={isLoginSubmit}
                appId="1044605643079052"
                fields="name,email,picture"
                // onClick={responseFacebook}
                cssClass="btn-login-with-facebook"
                callback={responseFacebook}
                textButton=""
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                    width="144px"
                    height="144px"
                  >
                    <linearGradient
                      id="Ld6sqrtcxMyckEl6xeDdMa"
                      x1="9.993"
                      x2="40.615"
                      y1="9.993"
                      y2="40.615"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0" stop-color="#2aa4f4" />
                      <stop offset="1" stop-color="#007ad9" />
                    </linearGradient>
                    <path
                      fill="url(#Ld6sqrtcxMyckEl6xeDdMa)"
                      d="M24,4C12.954,4,4,12.954,4,24s8.954,20,20,20s20-8.954,20-20S35.046,4,24,4z"
                    />
                    <path
                      fill="#fff"
                      d="M26.707,29.301h5.176l0.813-5.258h-5.989v-2.874c0-2.184,0.714-4.121,2.757-4.121h3.283V12.46 c-0.577-0.078-1.797-0.248-4.102-0.248c-4.814,0-7.636,2.542-7.636,8.334v3.498H16.06v5.258h4.948v14.452 C21.988,43.9,22.981,44,24,44c0.921,0,1.82-0.084,2.707-0.204V29.301z"
                    />
                  </svg>
                }
              ></FacebookLogin>
            </div>
            <div className="button-register" onClick={()=>{history.push('/register')}}>
              Not Account Yet ? Register now
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}

export default LoginScreen;
